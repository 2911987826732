/// <reference path="o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { Request, Response } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyHandler } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { SyncType } from 'o365.pwa.types.ts';
import type { ApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";

import type * as ApiPwaStrategyModule from 'o365.pwa.declaration.sw.strategies.api.pwa.strategy.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;


// TODO: Add better error handling
(() => {
    const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts");
    const { JsonDecoderStream } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts')>("o365.pwa.modules.sw.utilities.JsonDecoderStream.ts");
    const { restructureRecordForOfflineDB, restructureRecordForOnlineDB } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts')>("o365.pwa.modules.sw.O365OfflineDataRecord.ts");
    const { ApiPwaOfflineSyncOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.ts");
    const { ApiPwaOnlineSyncOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.d.ts')>("o365.pwa.modules.sw.apiRequestOptions.ApiPwaOnlineSyncRequestOptions.ts");
    const { CrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.CrudHandler.d.ts')>("o365.pwa.modules.sw.CrudHandler.ts");
    const { FileCrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.FileCrudHandler.d.ts')>("o365.pwa.modules.sw.FileCrudHandler.ts");

    class ApiPwaStrategy extends self.workbox.strategies.Strategy implements ApiPwaStrategyModule.ApiPwaStrategy {
        private readonly mode: SyncType;
        // private readonly ONLINE_SYNC = 'OnlineSync';
        // private readonly ONLINE_SYNC_RECORDS = 'OnlineSyncRecords';
        // private readonly ONLINE_SYNC_FILES = 'OnlineSyncFiles';

        constructor(options: ApiPwaStrategyModule.IApiPwaStrategyOptions) {
            super(options);

            this.mode = options.mode;
        }

        _handle(request: Request, handler: StrategyHandler) {
            switch (this.mode) {
                case 'OFFLINE-SYNC':
                    return this.handleOfflineSync(request, handler);
                case 'ONLINE-SYNC':
                    return this.handleOnlineSync(request, handler);
                case 'TRUNCATE':
                    return this.handleTruncateData(request, handler);
                default:
                    throw new Error(`Invalid mode: \`${this.mode}\``);
            }
        }

        private async handleOfflineSync(request: Request, handler: StrategyHandler): Promise<Response> {
            try {
                const requestOptions = await ApiPwaOfflineSyncOptions.fromRequest(request);
                const parsedOptions = requestOptions.parsedOptions;

                const jsonDataVersion = await this.handleOfflineSyncGenerateOfflineData(requestOptions, handler.event.clientId);

                const appId = parsedOptions.appIdOverride ?? parsedOptions.appId;
                const databaseId = parsedOptions.databaseIdOverride ?? "DEFAULT";
                const dataObjectId = parsedOptions.objectStoreIdOverride ?? parsedOptions.dataObjectId;

                const objectStoreRecord = await IndexedDBHandler.getObjectStore(appId, databaseId, dataObjectId);

                if (objectStoreRecord === null) {
                    this.handleSendStatusUpdate(handler.event.clientId, {
                        updateType: 'GenerateOfflineData',
                        status: 'Error',
                        requestGuid: parsedOptions.requestGuid,
                        error: "Failed to get objectStore"
                    });
                    throw new Error("Failed to get objectStore");
                }
                if (jsonDataVersion !== null && (objectStoreRecord.jsonDataVersion !== jsonDataVersion)) {
                    this.handleSendStatusUpdate(handler.event.clientId, {
                        updateType: 'GenerateOfflineData',
                        status: 'Error',
                        requestGuid: parsedOptions.requestGuid,
                        error: "jsonDataVersion mismatch, App update required."
                    });
                    throw new Error("jsonDataVersion mismatch, App update required.")
                }
                this.handleSendStatusUpdate(handler.event.clientId, {
                    updateType: 'GenerateOfflineData',
                    status: 'Complete',
                    requestGuid: parsedOptions.requestGuid,
                });

                await Promise.all([
                    this.handleOfflineSyncRowCount(requestOptions, handler.event.clientId),
                    this.handleOfflineSyncRetrieve(requestOptions, handler.event.clientId)
                ]);

                return new Response(JSON.stringify({}), {
                    status: 200,
                    statusText: 'OK',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                const responseBody = {
                    error: stringifiedReason
                };


                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            }
        }

        private async handleOnlineSync(request: Request, handler: StrategyHandler): Promise<Response> {
            try {
                const requestOptions = await ApiPwaOnlineSyncOptions.fromRequest(request);

                await this.handleOnlineSyncRowCount(requestOptions, handler.event.clientId);
                await this.handleOnlineSyncMerge(requestOptions, handler.event.clientId);

                return new Response(JSON.stringify({}), {
                    status: 200,
                    statusText: 'OK',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            }
        }

        private async handleTruncateData(request: Request, handler: StrategyHandler): Promise<Response> {
            try {

                let requestOptions = await ApiPwaOfflineSyncOptions.fromRequest(request);

                if (!requestOptions) throw new Error("Error occured when truncating data. Contact support.");

                const parsedOptions = requestOptions.parsedOptions;

                const clientId = handler.event.cliendId;

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'Truncate',
                    status: 'Start',
                    requestGuid: parsedOptions.requestGuid
                });

                if (parsedOptions.fileStore) {
                    self.o365.logger.log("Truncate FileStore");
                } else {
                    await this.truncateData(parsedOptions);
                }


                this.handleSendStatusUpdate(clientId, {
                    updateType: 'Truncate',
                    status: 'Complete',
                    requestGuid: parsedOptions.requestGuid
                });
                return new Response(JSON.stringify({}), {
                    status: 200,
                    statusText: 'OK',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            }
        }

        private async truncateData(options: InstanceType<typeof ApiPwaOnlineSyncOptions> | InstanceType<typeof ApiPwaOfflineSyncOptions>): Promise<void> {
            const appId = options.appIdOverride ?? options.appId;
            const databaseId = options.databaseIdOverride ?? "DEFAULT";
            const objectStoreId = options.objectStoreIdOverride ?? options.dataObjectId;

            const dexie = await CrudHandler.getDexieInstance({
                appId: appId,
                objectStoreId: objectStoreId,
                databaseIdOverride: databaseId,
                objectStoreIdOverride: options.objectStoreIdOverride,
            });

            try {
                await dexie.clear();
            } catch (reason: any) {
                throw reason;
            }
        }

        private async handleOfflineSyncGenerateOfflineData(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string): Promise<number | null> {
            try {
                const options = requestOptions.parsedOptions;

                if ((options.shouldGenerateOfflineData ?? false) === false) {
                    return null;
                }

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'GenerateOfflineData',
                    status: 'Start',
                    requestGuid: options.requestGuid
                });

                const generateOfflineDataBody = {
                    operation: 'execute',
                    procedureName: options.offlineDataProcName,
                    timeout: options.rowCountTimeout ?? 30,
                    useTransaction: true,
                    values: {
                        DeviceRef: options.deviceRef,
                        AppID: options.appId,
                        ViewName: options.originalViewName
                    }
                };

                const generateOfflineDataRequest = new Request(`/nt/api/data/${options.offlineDataProcName}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(generateOfflineDataBody)
                });

                const response = await fetch(generateOfflineDataRequest);
                const json = await response.json();

                if (!response.ok) {
                    throw new Error(`Offline sync procedure failed. ${json.error}, ${options.offlineDataProcName}`)
                }

                return json?.success?.Table[0]?.O365_JsonDataVersion ?? null;

            } catch (reason: any) {
                const options = requestOptions.parsedOptions;

                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                const responseBody = {
                    error: stringifiedReason
                };

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'GenerateOfflineData',
                    status: 'Error',
                    requestGuid: options.requestGuid,
                    error: reason
                });

                throw responseBody;
            }
        }

        private async handleOfflineSyncRowCount(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string): Promise<void> {
            try {
                const options = requestOptions.parsedOptions;

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'RowCount',
                    status: 'Start',
                    requestGuid: options.requestGuid
                });

                const rowCountBody = <{
                    dataObjectId: string,
                    fields: any,
                    maxRecords: number,
                    skip: number,
                    operation: string,
                    viewName: string,
                    masterDetailString?: string,
                    distinctRows?: boolean,
                    filterString?: string | null,
                    whereClause?: string | null
                    timeout: number
                }>{
                        dataObjectId: options.dataObjectId,
                        fields: options.fields,
                        maxRecords: -1,
                        skip: 0,
                        operation: 'rowcount',
                        viewName: options.viewName,
                        masterDetailString: options.masterDetailString,
                        distinctRows: options.distinctRows,
                        filterString: null,
                        whereClause: null,
                        timeout: 30 ?? options.rowCountTimeout,
                        expandView: false,
                        definitionProc: options.definitionProc,
                        definitionProcParameters: options.definitionProcParameters
                    };

                if (options.shouldGenerateOfflineData ?? false) {
                    rowCountBody.whereClause = `[AppID] = '${options.appId}' AND [Type] = '${options.offlineDataType}' AND [Status] = 'UNSYNCED'`;
                }

                const filterString = (options.filterString ?? '').trim();
                const whereClause = (options.whereClause ?? '').trim();

                if (filterString.length > 0 && whereClause.length > 0) {
                    rowCountBody.filterString = `(${filterString}) AND (${whereClause})`;
                } else if (filterString.length > 0) {
                    rowCountBody.filterString = filterString;
                } else if (whereClause.length > 0) {
                    rowCountBody.filterString = whereClause;
                } else {
                    delete rowCountBody.filterString;
                }

                const rowCountRequest = new Request(`/nt/api/data/${options.dataObjectId}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(rowCountBody)
                });

                const rowCountResponse = await fetch(rowCountRequest);
                const rowCountResponseJson = await rowCountResponse.json();

                if (rowCountResponseJson?.success?.total === 0 && options.failOnNoRecords) {
                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'RowCount',
                        status: 'Error',
                        requestGuid: options.requestGuid,
                        error: "No records to be downloaded."
                    });
                    return;
                }


                this.handleSendStatusUpdate(clientId, {
                    updateType: 'RowCount',
                    status: 'Complete',
                    requestGuid: options.requestGuid,
                    total: rowCountResponseJson?.success?.total
                });

            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
                const options = requestOptions.parsedOptions;

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'RowCount',
                    status: 'Error',
                    requestGuid: options.requestGuid,
                    error: stringifiedReason
                });

                throw reason;
            }
        }

        private async handleOfflineSyncRetrieve(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string): Promise<void> {
            try {
                const options = requestOptions.parsedOptions;

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'Retrieve',
                    status: 'Start',
                    requestGuid: options.requestGuid
                });

                let procedureOptions: IProcedureOptions = {
                    operation: 'execute',
                    procedureName: "",
                    timeout: 30,
                    useTransaction: true,
                    values: {
                        DeviceRef: options.deviceRef,
                        AppID: options.appId,
                        Type: options.offlineDataType
                    }
                };

                const retrieveBody = <{
                    dataObjectId: string,
                    fields: any,
                    maxRecords: number,
                    skip: number,
                    operation: string,
                    viewName: string,
                    masterDetailString?: string,
                    distinctRows?: boolean,
                    filterString?: string | null,
                    whereClause?: string | null
                }>{
                        dataObjectId: options.dataObjectId,
                        fields: options.fields,
                        maxRecords: -1,
                        skip: 0,
                        operation: 'retrieve',
                        viewName: options.viewName,
                        masterDetailString: options.masterDetailString,
                        distinctRows: options.distinctRows,
                        filterString: null,
                        whereClause: null,
                        expandView: options.expandView,
                        definitionProc: options.definitionProc,
                        definitionProcParameters: options.definitionProcParameters

                    };

                if (options.shouldGenerateOfflineData ?? false) {
                    retrieveBody.whereClause = `[AppID] = '${options.appId}' AND [Type] = '${options.offlineDataType}' AND [Status] = 'UNSYNCED'`;
                }

                const filterString = (options.filterString ?? '').trim();
                const whereClause = (options.whereClause ?? '').trim();

                if (filterString.length > 0 && whereClause.length > 0) {
                    retrieveBody.filterString = `(${filterString}) AND (${whereClause})`;
                } else if (filterString.length > 0) {
                    retrieveBody.filterString = filterString;
                } else if (whereClause.length > 0) {
                    retrieveBody.filterString = whereClause;
                } else {
                    delete retrieveBody.filterString;
                }

                const retrieveRequest = new Request(`/nt/api/data/stream/${options.dataObjectId}`, {
                    method: 'POST',
                    headers: new Headers({
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify(retrieveBody)
                });

                const retrieveResponse = await fetch(retrieveRequest);

                const reader = retrieveResponse.body?.getReader();

                if (reader === undefined) {
                    throw new Error('Failed to read response');
                }

                const decoder = new JsonDecoderStream();

                const dexieInstance = await CrudHandler.getDexieInstance({
                    appId: options.appId,
                    objectStoreId: options.dataObjectId,
                    appIdOverride: options.appIdOverride,
                    databaseIdOverride: options.databaseIdOverride,
                    objectStoreIdOverride: options.objectStoreIdOverride,
                });

                var numberOfRecords = 0;

                const insertPromises = new Array<Promise<void>>();
                const nonFileRecords = new Array<any>();
                const fileRecords = new Array<ApiPwaStrategyModule.IFile>();
                const isFileView = retrieveBody.fields.some((field: any) => field.name === 'FileRef');

                while (true) {
                    const { done, value } = await reader.read();

                    if (done) {
                        break;
                    }

                    //dbo.sviw_System_MyOfflineDataFiles
                    const records: Array<any> = [];
                    decoder.decodeChunk(value, (item: any) => {
                        if (options.shouldGenerateOfflineData) {
                            item = restructureRecordForOfflineDB(item);

                            item.O365_Status = 'SYNCED';
                        } else {
                            Object.keys(item).forEach((key: any) => {
                                if (key.endsWith("_JSON")) {
                                    item[key] = JSON.parse(item[key]);
                                }
                            })
                        }

                        records.push(item);

                        if (isFileView) {
                            // TODO: Better name
                            fileRecords.push(item);
                        } else {
                            nonFileRecords.push(item);
                        }
                    });

                    numberOfRecords += records.length;

                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'Retrieve',
                        status: 'RecordsDownloadedAndParsed',
                        requestGuid: options.requestGuid,
                        recordsDownloaded: numberOfRecords
                    });

                    if (records.length > 0) {
                        const bulkCreatePromise = dexieInstance.bulkPut(records);

                        insertPromises.push(bulkCreatePromise);

                        bulkCreatePromise.then(() => {
                            this.handleSendStatusUpdate(clientId, {
                                updateType: 'Retrieve',
                                status: 'RecordsStored',
                                requestGuid: options.requestGuid,
                                recordsInserted: numberOfRecords
                            });
                        });
                        records.length = 0;
                    }
                }

                reader.releaseLock();

                const bulkCreateResponses = await Promise.allSettled(insertPromises);

                const reasons = bulkCreateResponses.filter((promise) => promise.status === 'rejected').map((promise: PromiseSettledResult<void>) => (promise as PromiseRejectedResult).reason);

                if (reasons.length > 0) {
                    throw new Error(JSON.stringify(reasons));
                }
                if (isFileView) {
                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'FileRetrieve',
                        status: 'Start',
                        requestGuid: options.requestGuid
                    })
                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'FileRetrieve',
                        status: 'filesToSync',
                        requestGuid: options.requestGuid,
                        filesToSync: numberOfRecords
                    });

                    await this.handleOfflineSyncDownloadFiles(fileRecords, requestOptions, clientId, options.requestGuid);

                    procedureOptions.procedureName = "sstp_System_OfflineDataFilesStatusUpdate";

                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'FileRetrieve',
                        status: 'Complete',
                        requestGuid: options.requestGuid,
                    });
                } else {

                    procedureOptions.procedureName = "sstp_System_OfflineDataStatusUpdate";

                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'Retrieve',
                        status: 'Complete',
                        requestGuid: options.requestGuid,
                        recordsSynced: numberOfRecords
                    });
                }

                await this.handleOfflineDataStatusUpdate(procedureOptions, requestOptions);

            } catch (reason: any) {
                throw reason;
            }
        }

        private async handleOfflineDataStatusUpdate(procedureOptions: ApiPwaStrategyModule.IProcedureOptions, requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>): Promise<void> {
            if (!requestOptions.parsedOptions.shouldGenerateOfflineData) {
                return;
            }
            const updateStatusRequset = new Request(`/nt/api/data/${procedureOptions.procedureName}`, {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(procedureOptions)
            });

            const response = await fetch(updateStatusRequset);
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status update procedure failed. ${json.error}, ${procedureOptions.procedureName}`)
            }
        }

        private async handleOfflineSyncDownloadFiles(files: Array<ApiPwaStrategyModule.IFile>, requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>, clientId: string, requestGuid: string) {
            const options = requestOptions.parsedOptions;

            const requests = new Array<Promise<any>>();
            let updatedFileCount = 0;
            const sendUpdate = (inc: number) => {
                updatedFileCount += inc;
                this.handleSendStatusUpdate(clientId, {
                    updateType: 'FileRetrieve',
                    status: 'filesToSyncUpdate',
                    requestGuid: options.requestGuid,
                    updatedFileCount: updatedFileCount
                });
            }
            for (const file of files) {
                const primKey = file.O365_PrimKey ?? file.PrimKey;
                if (this.canRetrievePdf(file.Extension)) {
                    file.PdfRef = self.crypto.randomUUID();
                    sendUpdate(2);
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=original`, clientId, requestGuid, requestOptions)); // original
                    requests.push(this.handleOfflineSyncDownloadFile(file, "PDF", `/nt/api/download-pdf/${options.viewName}/${primKey}`, clientId, requestGuid, requestOptions)); // pdf
                } else if (file.Extension === "pdf") {
                    sendUpdate(1);
                    requests.push(this.handleOfflineSyncDownloadFile(file, "PDF", `/nt/api/download-pdf/${options.viewName}/${primKey}`, clientId, requestGuid, requestOptions)); // pdf
                } else if (this.isImage(file.Extension)) {
                    file.ThumbnailRef = self.crypto.randomUUID();
                    sendUpdate(2);
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=original`, clientId, requestGuid, requestOptions)); // optimized
                    requests.push(this.handleOfflineSyncDownloadFile(file, "THUMBNAIL", `/nt/api/file/download/${options.viewName}/${primKey}?scale=thumbnail`, clientId, requestGuid, requestOptions)); // thumbnail
                } else {
                    sendUpdate(1);
                    requests.push(this.handleOfflineSyncDownloadFile(file, "ORIGINAL", `/nt/api/file/download/${options.viewName}/${primKey}`, clientId, requestGuid, requestOptions));
                }
            }

            const results = await Promise.allSettled(requests);

            return results;
        }

        private canRetrievePdf(extension: string) {
            const dict = [
                ...["doc", "docx", "rtf", "dot", "dotx", "dotm", "docm", "odt", "ott"],
                ...["xls", "xlsx", "xlsb", "xlt", "xltx", "xltm", "xlsm", "ods"],
                ...["msg", "pst", "ost", "oft", "eml", "emlx", "mbox"],
                ...["ppt", "pptx", "pps", "pot", "ppsx", "pptm", "ppsm", "potx", "potm"],
                ...["txt", "csv"],
                ...["dxf", "cad", "dwg", "dwt", "plt", "cf2", "pcl", "hpgl", "dgn", "stl", "iges"]
            ];
            return dict.includes(extension);
        }
        private isImage(extension: string) {
            const dict = ["png", "jpeg", "jpg"];
            return dict.includes(extension);
        }
        public extractFilename = (header: string): string | null => {
            // Regular expression matches both with and without quotes

            const match = header.match(/filename="?([^"]+)"?/);
            return match ? match[1] : null;
        }

        private async handleOfflineSyncDownloadFile(file: ApiPwaStrategyModule.IFile, type: FileType, url: string, clientId: string, requestGuid: string, requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOfflineSyncOptions>>) {
            try {


                const options = requestOptions.parsedOptions;
                const request = new Request(url, { method: 'GET' });

                const response = await fetch(request);

                if (response.status !== 200) {
                    throw new Error(`Error retrieving file of type ${type}, ${response.status}: ${response.type} - ${await response.text()}`);
                }

                const responseBlob = await response.blob();

                let attach;

                switch (type) {
                    case "ORIGINAL":
                        attach = {
                            PrimKey: file.FileRef,
                            PdfRef: file.PdfRef,
                            ThumbnailRef: file.ThumbnailRef,
                        };

                        break;

                    case "PDF":
                        const contentDisp = response.headers.get("Content-Disposition");

                        if (!contentDisp) throw Error("Content-Disposition undefined.");

                        attach = {
                            FileName: this.extractFilename(contentDisp) ?? file.FileName,
                            Extension: "pdf",
                            PrimKey: file.PdfRef ?? file.FileRef,
                        };

                        break;
                    case "THUMBNAIL":
                        attach = {
                            PrimKey: file.ThumbnailRef,
                        };

                        break;
                }

                let staticRecord = {
                    FileName: file.FileName,
                    Extension: file.Extension,
                    FileSize: responseBlob.size,
                    Data: responseBlob,
                    MimeType: responseBlob.type,
                    appID: options.appIdOverride ?? options.appId
                };

                const insertedFileRef = await FileCrudHandler.handleUpload({ ...staticRecord, ...attach, PrimKey: attach?.PrimKey! });

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'FileRetrieve',
                    status: 'FileInserted',
                    requestGuid: requestGuid
                });

                return insertedFileRef;
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'FileRetrieve',
                    status: 'Error',
                    requestGuid: requestGuid,
                    error: stringifiedReason
                });
                throw reason;
            }
        }

        private async handleOnlineSyncRowCount(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOnlineSyncOptions>>, clientId: string): Promise<void> {
            try {
                const requestBody = requestOptions.parsedOptions;

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'RowCount',
                    status: 'Start',
                    requestGuid: requestBody.requestGuid
                });

                const rowCount = await CrudHandler.handleRetrieveRowcount({
                    appId: requestBody.appId,
                    dataObjectId: requestBody.dataObjectId,
                    objectStoreIdOverride: requestBody.objectStoreIdOverride,
                    fields: [],
                }, ['CREATED', 'UPDATED', 'DESTROYED', 'FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED']);

                this.handleSendStatusUpdate(clientId, {
                    updateType: 'RowCount',
                    status: 'Complete',
                    requestGuid: requestBody.requestGuid,
                    recordsToSync: rowCount
                });
            } catch (reason: any) {
                throw reason;
            }
        }

        private async handleOnlineSyncMerge(requestOptions: ApiRequestOptions<InstanceType<typeof ApiPwaOnlineSyncOptions>>, clientId: string): Promise<void> {
            try {
                const requestBody = requestOptions.parsedOptions;
                /*
                ONLINE SYNC START
                */
                this.sendStatusUpdate(clientId, 'OnlineSync', 'Start', requestBody.requestGuid);

                const records = await this.retrieveRecords(requestBody);

                /*
                 RECORDS SYNC
                */
                this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'Start', requestBody.requestGuid, records.length);

                const recordsToSync = records.filter((record) => ['CREATED', 'UPDATED', 'DESTROYED'].includes(record.O365_Status));
                await this.syncRecords(recordsToSync, clientId, requestBody, requestBody.truncateMode);

                this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'Complete', requestBody.requestGuid, records.length);

                /*
                 FILE RECORDS SYNC
                */
                this.sendStatusUpdate(clientId, 'OnlineSyncFiles', 'Start', requestBody.requestGuid, records.length);

                const appRecordsToSync = records.filter((record) => ['FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED'].includes(record.O365_Status))
                await this.handleAppRecords(appRecordsToSync, requestBody, clientId);
                await this.syncAppRecords(appRecordsToSync, clientId, requestBody, requestBody.truncateMode);

                //TODO: Truncate Data

                this.sendStatusUpdate(clientId, 'OnlineSyncFiles', 'Complete', requestBody.requestGuid);

                /*
                ONLINE SYNC END
                */
                this.sendStatusUpdate(clientId, 'OnlineSync', 'Complete', requestBody.requestGuid);

            } catch (reason: any) {
                throw reason;
            }
        }

        private async retrieveRecords(requestBody: any): Promise<any[]> {
            return CrudHandler.handleRetrieve({
                appId: requestBody.appId,
                objectStoreId: requestBody.dataObjectId,
                objectStoreIdOverride: requestBody.objectStoreIdOverride,
                fields: [],
            }, ['CREATED', 'UPDATED', 'DESTROYED', 'FILE-CREATED', 'FILE-UPDATED', 'FILE-DESTROYED']);
        }

        private sendStatusUpdate(clientId: string, updateType: string, status: string, requestGuid: string, count?: number, error?: string) {
            this.handleSendStatusUpdate(clientId, {
                updateType,
                status,
                requestGuid,
                ...(count !== undefined && { recordsToSync: count }),
                ...(error && { error })
            });
        }

        private async syncRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode) {
            if (records.length === 0) return;
            this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'RecordsToSync', requestBody.requestGuid, records.length);

            const restructuredRecords = this.restructureRecords(records, "RECORD", requestBody);
            const options = this.getOptions("sstp_System_OfflineDataOnlineSync", restructuredRecords);
            const response = await this.sendDataToApi(options);

            if (!response.ok) {
                this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'Failed', requestBody.requestGuid, records.length, await response.text());
            } else {
                await this.updateSyncedRecords(records, clientId, requestBody, truncateMode);
            }
        }

        private async syncAppRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode) {
            if (records.length === 0) return;
            const restructuredRecords = this.restructureRecords(records, "FILERECORD", requestBody);
            const options = this.getOptions("sstp_System_OfflineDataFilesOnlineSync", restructuredRecords);
            const response = await this.sendDataToApi(options);

            if (!response.ok) {
                this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'Failed', requestBody.requestGuid, records.length, await response.text());
            } else {
                this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'RecordsToSync', requestBody.requestGuid, records.length);
                await this.updateSyncedRecords(records, clientId, requestBody, truncateMode);
            }
        }

        private restructureRecords(records: any[], type: "RECORD" | "FILERECORD", requestBody: any): any[] {
            switch (type) {
                case 'FILERECORD':
                    return records.map(record => restructureRecordForOnlineDB(record)).map(record => [
                        record.PrimKey,
                        record.Created,
                        record.CreatedBy_ID,
                        record.Updated,
                        record.UpdatedBy_ID,
                        record.Status,
                        record.JsonData,
                        record.UpdatedFields,
                        record.OriginalValues,
                        record.JsonDataVersion,
                        record.FileName,
                        record.FileSize,
                        record.FileUpdated,
                        record.FileRef,
                        record.Type,
                        record.AppID,
                        requestBody.deviceRef
                    ]);
                case 'RECORD':
                    return records.map(record => restructureRecordForOnlineDB(record)).map(record => [
                        record.PrimKey,
                        record.Created,
                        record.CreatedBy_ID,
                        record.Updated,
                        record.UpdatedBy_ID,
                        record.Status,
                        record.JsonData,
                        record.UpdatedFields,
                        record.OriginalValues,
                        record.JsonDataVersion,
                        record.Type,
                        record.AppID,
                        record.ExternalRef,
                        requestBody.deviceRef
                    ]);
            }
        }


        private getOptions(procedureName: string, restructuredRecords?: any[]): any {
            if (restructuredRecords) {
                return {
                    Operation: "execute",
                    ProcedureName: procedureName,
                    UseTransaction: true,
                    Timeout: 60,
                    Values: { "OfflineData": restructuredRecords }
                };
            }
            return {
                Operation: "execute",
                ProcedureName: procedureName,
                UseTransaction: true,
                Timeout: 60,
            };
        }

        private async sendDataToApi(options: any): Promise<Response> {
            return await fetch('/nt/api/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(options),
            }) as Response;
        }

        private async updateSyncedRecords(records: any[], clientId: string, requestBody: any, truncateMode: TruncateIndexDBObjectStoreMode) {
            for (let record of records) {
                if (truncateMode === "TRUNCATE_AFTER_ONLINE_RECORD_SYNC") {
                    //TODO: Delete record
                    await CrudHandler.handleDestroy({
                        appId: requestBody.appId,
                        dataObjectId: requestBody.dataObjectId,
                        providedRecord: record,
                        objectStoreIdOverride: requestBody.objectStoreIdOverride
                    });

                    this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'RecordSynced', requestBody.requestGuid);
                } else {
                    const updatedRecord = { ...record, O365_Status: "SYNCED" } as any;
                    const response = await CrudHandler.handleUpdate({
                        appId: requestBody.appId,
                        dataObjectId: requestBody.dataObjectId,
                        providedRecord: updatedRecord,
                        objectStoreIdOverride: requestBody.objectStoreIdOverride
                    });
                    if (response.O365_Status !== "SYNCED") {
                        this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'RecordFailed', requestBody.requestGuid, undefined, "Updating app record status failed.");
                        throw new Error("Updating app record status failed.");
                    } else {
                        this.sendStatusUpdate(clientId, 'OnlineSyncRecords', 'RecordSynced', requestBody.requestGuid);
                    }
                }
            }
        }

        private async handleAppRecords(records: any, requestBody: any, clientId: string) {
            for (const appRecord of records) {
                const file = await FileCrudHandler.handleView({ FileRef: appRecord.FileRef });

                if (!file || !file.dataAsBlob) {
                    throw new Error("Unable to retrieve file.");
                }

                const chunks = ApiPwaStrategy.chunkBlob(file.dataAsBlob);
                if (!chunks || chunks.length === 0) {
                    throw new Error("Something went wrong while creating chunks of file.");
                }

                let uploadRef: string | null = appRecord.PrimKey ?? null;

                for (let chunk of chunks) {
                    const formData = new FormData();
                    formData.append('File', chunk.chunk, file.filename);

                    if (!uploadRef) {
                        throw new Error("no UploadRef");
                    }

                    const requestUrl = `/nt/api/file/chunkupload/${uploadRef}`;

                    const response = await fetch(requestUrl, {
                        method: 'POST',
                        headers: new Headers({
                            'Custom-Content-Range': chunk.ccr,
                            'Accept': 'application/json'
                        }),
                        body: formData
                    });

                    const responseJson = await response.json();
                    uploadRef = responseJson.uploadRef;
                    const ccr = FileCrudHandler.getContentRange(chunk.ccr);

                    if (ccr?.end === ccr?.total! - 1 && responseJson.fileRef) { // uploadRef will be newest fileRef
                        const fileRef = responseJson.fileRef;

                    /* const newFileRecord = */ await FileCrudHandler.handleFileUpdate(file, Object.assign({ primKey: fileRef }));
                        const newAppRecord = await CrudHandler.handleUpdate({
                            appId: requestBody.appId,
                            dataObjectId: requestBody.dataObjectId,
                            objectStoreIdOverride: requestBody.objectStoreIdOverride,
                            providedRecord: {
                                ...appRecord,
                                FileRef: fileRef,
                                O365_Status: "SYNCED"
                            }
                        });

                        appRecord.FileRef = newAppRecord.FileRef;
                    }
                    this.handleSendStatusUpdate(clientId, {
                        updateType: 'OnlineSyncFiles',
                        status: 'FileSynced',
                        requestGuid: requestBody.requestGuid,
                    });
                }
            }
        }


        static chunkBlob(blob: Blob, chunkSize: number = (4 * 1024 * 1024)): Array<{ chunk: Blob, ccr: string }> {
            const chunks = [];

            for (let start = 0; start < blob.size; start += chunkSize) {
                const end = Math.min(start + chunkSize, blob.size);
                const chunk = blob.slice(start, end);
                chunks.push({ chunk: chunk, ccr: `bytes ${start}-${end - 1}/${blob.size}` });
            }

            return chunks;
        }

        private async handleSendStatusUpdate(clientId: string, message: any) {
            const client = await self.clients.get(clientId);

            client?.postMessage(message);
        }
    }

    self.o365.exportScripts({ ApiPwaStrategy });
})();
